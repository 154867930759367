import React from 'react'


const Sliders = () => {

  return (
    <>
      <div className="slider">
      <div className="slide-track">
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-8.png"
          />
          <h5>Shoping</h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
          />
          <h5>Pub & Bar</h5>
          <p>(5 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-9.png"
          />
          <h5>Places</h5>
          <p>(1 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-3.png"
          />
          <h5>Destination</h5>
          <p>(2 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-7.png"
          />
          <h5>Fitness & Gym
          </h5>
          <p>(14 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-6.png"
          />
          <h5>Hotel & Stay
          </h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-2.png"
          />
          <h5>Education</h5>
          <p>(19 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-8.png"
          />
          <h5>shoping</h5>
          <p>(9 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
          />
          <h5>Shoping</h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-9.png"
          />
          <h5>Shoping</h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-3.png"
          />
          <h5> Shoping </h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-7.png"
          />
          <h5>Shoping</h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-6.png"
          />
          <h5>Shoping</h5>
          <p>(15 Listing)</p>
        </div>
        <div className="slide">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-2.png"
          />
          <h5>Shoping</h5>
          <p>(15 Listing)</p>
        </div>
      </div>
    </div>
      
    </>
  )
}

export default Sliders
