import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Pages/Home";
import ContactUs from "./components/Pages/ContactUs";
import Authentication from "./components/Authentication";
import Listing from "./components/Pages/Listing";
import About from "./components/Pages/About";
import Team from "./components/Pages/Team";
import Discover from "./components/Pages/Discover";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import ErrorPage from "./components/Pages/ErrorPage";
import Terms from "./components/Pages/policies/Terms";
import Privacy from "./components/Pages/policies/Privacy";
import Dashboard from "./components/Admin/Dashboard";
import EventTable from "./components/Admin/EventTable";
// import Admin from "./components/Admin";
// import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  let isLogin = false;
  let x = Cookies.get("login");
  if (x !== undefined) {
    isLogin = true;
  } else {
    isLogin = false;
  }

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={isLogin === true ? (<Home />) : (<Navigate to='/login' replace />)} /> */}

          {/* Navbar Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />

          {/* login/signup route */}
          {/* <Route
            path="/login"
            element={
              isLogin === false ? (
                <Authentication />
              ) : (
                <Navigate to="/" replace />
              )
            }
          /> */}
          <Route path="/login" element={<Authentication/>}/>
          {/* other page routes */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Privacy />} />
          <Route path="*" element={<ErrorPage />} />
      {/* Admin Dashboard routes */}
      <Route path="/dashboard" element={ <Dashboard/> }/>
      <Route path="/events" element={ <EventTable/> }/>
        </Routes>
      </Router>

      {/* for errors */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        // pauseOnHover
        theme="dark "
      />
    </>
  );
}

export default App;
