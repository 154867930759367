import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, Dropdown  } from "react-bootstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

function Header() {
  let isLogin = false;
  let x = Cookies.get("login");
  if (x !== undefined) {
    isLogin = true;
  } else {
    isLogin = false;
  }
  // const handleLogout = () => {
  //   Swal.fire({
  //     title: "Are you sure you want to log out?",
  //     icon: "warning",
  //     // buttons: true,
  //     // dangerMode: true,
  //   }).then((logout) => {
  //     if (logout) {
  //       localStorage.removeItem("user-data");
  //       document.cookie = "login=false;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  //       window.location.replace("/login");
  //     }
  //   });
  // };
  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className="navbar navbar-expand-md navbar-dark bg-dark fixed-top px-0 py-2"
      >
        <div className="container-fluid">
          {/* <!-- Logo --> */}
          <Link className="navbar-brand">
            <div className="header_logo">
              <img src="assets/images/lueurface.png" alt="..." />
            </div>
          </Link>
          {/* <!-- Navbar toggle --> */}
          <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          {/* <!-- Collapse --> */}
          <Navbar.Collapse
            className="collapse navbar-collapse"
            id="navbarCollapse"
          >
            {/* <!-- Nav --> */}
            <Nav className="navbar-nav mx-lg-auto">
              <Link to="/" className="nav-item nav-link " aria-current="page">
                Home
              </Link>
              <Link to="/discover" className="nav-item nav-link">
                Discover
              </Link>
              <Link to="/listing" className="nav-item nav-link">
                Listing
              </Link>
              <Link to="/about" className="nav-item nav-link">
                About Us
              </Link>
              <Link to="/contactus" className="nav-item nav-link">
                Contact Us
              </Link>
              <Link to="/team" className="nav-item nav-link">
                Our Team
              </Link>
            </Nav>
            <Nav className="navbar-nav ms-lg-4">
              {/* Show the logout link only if the user is logged in */}
              {isLogin && (
                <>
                   <Dropdown
                    data-bs-theme="dark"
                   as={Nav.Item}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="nav-item nav-link dropdown-toggle caret"
                    as={Link}
                    data-toggle="tooltip"
                    title="Login/Signup"
                  >
                    <i
                      className="fa fa-user"
                      style={{ cursor: "pointer", fontSize: "24px" }}
                    ></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="bg-dark text-center">
                    {/* Add the Dashboard link */}
                    <Dropdown.Item
                     className="text-white"
                    as={Link} to="/dashboard">
                      Dashboard
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </>
              )}

              {/* Show the login link if the user is not logged in */}
                {!isLogin && (
                <Dropdown
                data-bs-theme="dark"
                as={Nav.Item}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="nav-item nav-link dropdown-toggle caret "
                    as={Link}
                    data-toggle="tooltip"
                    title="Login/Signup"
                  >
                    <i
                      className="fa fa-user"
                      style={{ cursor: "pointer", fontSize: "24px" }}
                    ></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="text-center">
                    {/* Add the Dashboard link */}
                    {/* <Dropdown.Item
                     className="text-white"
                    as={Link} to="/dashboard">
                      Dashboard
                    </Dropdown.Item> */}
                    <Dropdown.Item 
                    className="text-white"
                    as={Link} to="/login">
                      Login
                    </Dropdown.Item>
                    {/* Add other dropdown items if needed */}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default Header;
