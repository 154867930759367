import React from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import { Helmet } from "react-helmet";
const Privacy = () => {

 

  return (
    <div>
      <Helmet>
        <title>Tricity ~ Privacy & Policy</title>
      </Helmet>
        <Header/>
      <section className="lttle_wraper" style={{ marginTop: "7em" }}>
  
        <div className="container">
          <div className="row">
            <h1 className="lttle_title text-center">Privacy Policy</h1>

            <div className="col-lg-12">
              <div className="about-lttle pt-100">
                <div className="about-lttle__content">
                  <p>
                    We have adopted this Privacy Policy (“Policy”) to govern how
                    we collect, store, use, process and disclose the information
                    that you choose to give us online through Littledraw.ae.
                    This Policy is subject to all governing laws and regulations
                    regarding privacy protection.
                  </p>
                  <p>
                    We may revise this Policy from time to time by posting the
                    amended terms on Littledraw.ae. All amended terms
                    automatically take effect immediately upon posting. Your
                    continued use of littledraw.ae after an updated Privacy
                    Policy is posted signifies that you accept all its
                    revisions.
                  </p>
                  <p>
                    We recognizes a special obligation to protect personal data
                    of minors. Therefore, anyone under 18 years of age are
                    requested not to use this site.
                  </p>
                  <p>
                    This Privacy Policy was written in English. To the extent
                    any translated version of this Privacy Policy conflicts with
                    the English version, the English version prevails.
                  </p>
                  <br />
                  <h6>YOUR PERSONAL DATA</h6>
                  <br />
                  <p>
                    “Personal data” means any information from which it is
                    practicable for the identity of an individual to be
                    ascertained. The purposes for which we may use your personal
                    data are divided into obligatory purposes and voluntary
                    purposes. If personal data is to be used for an obligatory
                    purpose, you must provide your personal data to us if you
                    want us to provide the service for which you are applying.
                    If personal data is to be used only for a voluntary purpose,
                    it is entirely up to you to decide whether you want to
                    provide such information to us or not.
                  </p>
                  <br />
                  <h6>
                    The personal data that we ask you to provide on a voluntary
                    basis are:
                  </h6>
                  <br />
                  <p>
                    Name prefix (e.g., Mr., Ms.) Secondary telephone numbers
                    Date of birth Passport / Emirates ID number any registration
                    number previously issued to you by us
                  </p>
                  <p>
                    The purposes for which it is only voluntary for you to
                    provide your personal data are to enable us to communicate
                    with littledraw.ae visitors and business partners, develop
                    systems, services and procedures and information offerings
                    tailored to your needs and perform market research. If any
                    of our communications constitute direct marketing, we will
                    separately seek your consent where required by applicable
                    law.
                  </p>
                  <br />
                  <h6>
                    The personal data which is obligatory for you to provide in
                    order to receive registration services are
                  </h6>
                  <br />
                  <p>
                    Full name Primary telephone numbers E-mail address
                    Nationality Country of residence Passport / Emirates ID
                    number any password you previously created for your
                    registration.
                  </p>
                  <br />
                  <h6>
                    It is obligatory for you to provide your personal data in
                    paragraph above for us to provide the following services:
                  </h6>
                  <br />
                  <p>
                    maintaining your registration; informing you of benefits
                    available through your registration; relationship building;
                    data cleansing and profile updates; enable us to process
                    your order on littledraw.ae and detect and prevent fraud.
                  </p>

                  <br />
                  <h6>
                    <span className="shape_circle">1</span>Your Information
                  </h6>
                  <br />
                  <p>
                    <strong>The type of information: </strong>we collect from
                    you is either personal to you, or is general in nature:
                  </p>
                  <p>
                    Personal Information: When you register on the Platform as a
                    member, update your information, purchase any goods or
                    services, take part in promotions or send emails to Us, you
                    provide Us with information that We store and process. Such
                    information may include your name, address, phone number,
                    email address, purchase and transaction history, interests,
                    and other such ‘Personal Information’.
                  </p>
                  <p>
                    <strong>General Information: </strong>We also receive more
                    general information from you as a result of your visits to,
                    and use of, the Platform. This general information does not
                    identify you personally, and can include information such as
                    your IP address, the date and time you access the Platform,
                    length of time you spend on the Platform, your browsing
                    history (recorded by the text and graphics files that
                    compose that page), the Internet address of the website from
                    which you linked directly to our Platform, and other such
                    ‘General Information’.
                  </p>
                  <br />
                  <h6>
                    <span className="shape_circle">2</span>Collecting and Using
                    Information
                  </h6>
                  <br />
                  <p>
                    All credit/debit cards details and personally identifiable
                    information will NOT be stored, sold, shared, rented or
                    leased to any third parties.
                  </p>
                  <p>
                    Most of the Platform can be used without giving Us any
                    information. However, certain services may involve
                    collection of information from you. We use this information
                    to provide you with a better service, and in particular to:
                    keep internal records; improve our products, services and
                    the Platform; communicate with you if you have ordered,
                    purchased or participated in anything on the Platform;
                    contact you for market research purposes; and provide you
                    with information about new opportunities, promotions,
                    special offers and other information that We may feel is
                    relevant to you. If you contact Us via the Platform, or via
                    email to the contacts set out on the Platform, We may keep a
                    record of that correspondence.
                  </p>
                  <p>
                    We use General Information to help Us make the Platform more
                    useful to visitors (including to help Us assess content of
                    interest to visitors), and for other purposes such as
                    determining the Platform’s technical design specifications
                    and identifying system performance or problem areas, for
                    tracking activity on the Platform, preparing reports,
                    assessing trends, and otherwise monitoring the way in which
                    the Platform is being used none of which would be in a way
                    that personally identifies any users.
                  </p>
                  <p>
                    Some of the advertisements you see on the Platform are
                    selected and delivered by third parties, such as ad
                    networks, advertising agencies, advertisers, and audience
                    segment providers. These third parties may collect
                    information about you and your online activities, either on
                    the Platform or on other websites, through cookies, web
                    beacons, and other technologies in an effort to understand
                    your interests and deliver to you advertisements that are
                    tailored to your interests. Please remember that We do not
                    have access to, or control over, the information these third
                    parties may collect. The information practices of these
                    third parties are not covered by this privacy policy.
                  </p>
                  <br />
                  <h6>
                    <span className="shape_circle">3</span>Non-Disclosure of
                    Personal Information
                  </h6>
                  <br />
                  <p>
                    You agree that We may share your Personal Information within
                    Our company. We will not share, sell or rent your Personal
                    Information in any manner to any third parties outside Our
                    company except when we have your permission; or if required
                    to do so by law or any governing authority; or to protect
                    our own legitimate interests.
                  </p>
                  <br />
                  <h6>
                    <span className="shape_circle">4</span>Security
                  </h6>
                  <br />
                  <p>
                    Keeping your Personal Information secure and preventing
                    unauthorized access is of utmost priority to Us, and We take
                    all steps reasonably necessary to protect your Personal
                    Information against any unauthorised access, use,
                    alteration, disclosure or destruction. Whilst We have put in
                    place physical, electronic and managerial procedures to
                    secure and safeguard your Personal Information, We will not
                    be held responsible for any unauthorised access by third
                    parties and We cannot guarantee that the Personal
                    Information provided by you or that is transmitted via the
                    Platform or by e-mail is totally secure and safe.
                  </p>
                  <br />
                  <h6>
                    <span className="shape_circle">5</span>Use of Cookies
                  </h6>
                  <br />

                  <p>
                    Payment and associated information is handled by Network
                    International-hosted payment gateway and Littledraw will not
                    store any credit card information.
                  </p>
                  <p>
                    Under certain circumstances, Littledraw may be required to
                    disclose your personal data if required to do so by law or
                    in response to valid request by public authorities (e.g. a
                    court or government agency).
                  </p>
                </div>
              </div>
              {/* <!-- about-wrapper--> */}
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default Privacy;
