import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Apis from "../../tricitiApis";
import Admin from "./Admin";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Stepper, Step, StepLabel } from '@mui/material';

const EventForm = () => {
  const {
    eventdata,
    setEventData,
    currentStep,
    nextStep,
    prevStep,
    handleChange,
    handleSubmit,
    category,
    setCategory,
    subCategories,
    errorFields,
    showmodal,
    setShowModal,
  } = Admin();
  const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] = useState(false);
  const [isEndDateCalendarOpen, setIsEndDateCalendarOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [city, setCities] = useState([]);
 
  useEffect(() => {
    Apis.getStates()
      .then((response) => {
        // console.log("state data:", response.data.listing);
        if (response.data.success) {
          setStates(response.data.listing);
        } else {
          console.error("Failed to fetch states:", response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });

    Apis.getCity()
      .then((res) => {
        // console.log("city data:", res.data.listing);
        if (res.data.success) {
          setCities(res.data.listing);
        } else {
          console.error("Failed to fetch states:", res.data.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });

    // if (eventdata.category_id == '') {
    Apis.getCategory(eventdata.category_id)
      .then((res) => {
        if (res.data.success) {
          setCategory(res.data.Category_Listing);
        } else {
          console.error("Failed to fetch category:", res.data.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
    // }
  }, []);

  return (
    <div>
      <div className="card-header">
        Events Data Table
        <Button
          type="button"
          className="btn bg-dark text-white"
          onClick={()=>setShowModal(true)}
        >
          Add Event +
        </Button>
        {/* <!-- Modal --> */}
        <Modal show={showmodal} onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}>
          {/* <div className="modal-dialog"> */}
            <Modal.Header closeButton>
              <Modal.Title>Add New Event</Modal.Title>
            </Modal.Header>
            {/* <div className="modal-content"> */}
              <Modal.Body>
                {/* <div className="modal-body"> */}
                <div id="container" className="container mt-5">
                  {/* <div className="progress px-1" style={{ height: "3px" }}> */}
                  <Stepper activeStep={currentStep - 1} alternativeLabel>
                  <Step>
                    <StepLabel>Step 1</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Step 2</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Step 3</StepLabel>
                  </Step>
                </Stepper>
                  <form id="multi-step-form">
                    {/* <div className="step step-1"> */}
                    <div
                      className={`step step-1 ${
                        currentStep === 1 ? "animate__fadeInRight" : ""
                      }`}
                    >
                      {currentStep === 1 && (
                        <>
                          <div className="mb-3">
                            <h3>Step 1</h3>
                            <div className="row">
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  // className="form-control"
                                  placeholder="event_tittle"
                                  aria-label="event_tittle"
                                  name="event_tittle"
                                  value={eventdata.event_tittle}
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errorFields.includes("event_tittle")
                                      ? "error"
                                      : ""
                                  }`}
                                />
                              </div>

                              <div className="col-sm-6">
                                
                                <select
                                  // className="form-select form-select-lg mb-3"
                                  className={`form-control ${
                                    errorFields.includes("category_id")
                                      ? "error"
                                      : ""
                                  }`}
                                  aria-label=".form-select-lg example"
                                  name="category_id"
                                  value={eventdata.category_id}
                                  onChange={handleChange}
                                >
                                  {/* <option defaultValue>category_id</option> */}
                                  <option defaultValue>Select Category</option>
                                  {category &&
                                    category.map((elm) => (
                                      <option key={elm.id} value={elm.id}>
                                        {elm.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-sm-6">
                                <select
                                  // className="form-select form-select-lg mb-3"
                                  className={`form-control ${
                                    errorFields.includes("sub_category")
                                      ? "error"
                                      : ""
                                  }`}
                                  aria-label=".form-select-lg example"
                                  name="sub_category"
                                  value={eventdata.sub_category}
                                  onChange={handleChange}
                                >
                                  <option defaultValue>sub Category</option>
                                  {subCategories.map((subCategory) => (
                                    <option
                                      key={subCategory.id}
                                      value={subCategory.id}
                                    >
                                      {subCategory.name}
                                    </option>
                                  ))}
                                  {/* <option value="" disabled>
                                    No Data
                                  </option> */}
                                </select>
                              </div>

                              <div className="col-sm-6">
                                <input
                                  type="time"
                                  className={`form-control ${
                                    errorFields.includes("timing")
                                      ? "error"
                                      : ""
                                  }`}
                                  placeholder="timing "
                                  aria-label="timing"
                                  name="timing"
                                  value={eventdata.timing}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-sm-6">
                                <DatePicker
                                  // className="form-control"
                                  className={`form-control col-sm-6 ${
                                    errorFields.includes("start_date")
                                      ? "error"
                                      : ""
                                  }`}
                                  id="start_date"
                                  selected={eventdata.start_date}
                                  onChange={(date) =>
                                    setEventData({
                                      ...eventdata,
                                      start_date: date,
                                    })
                                  }
                                  placeholderText="Start Date"
                                  onFocus={() =>
                                    setIsStartDateCalendarOpen(true)
                                  }
                                  onBlur={() =>
                                    setIsStartDateCalendarOpen(false)
                                  }
                                  open={isStartDateCalendarOpen}
                                />
                              </div>
                              <div className="col-sm-6">
                                <DatePicker
                                  className={`form-control ${
                                    errorFields.includes("end_date")
                                      ? "error"
                                      : ""
                                  }`}
                                  id="end_date"
                                  selected={
                                    eventdata.end_date 
                                  }
                                  onChange={(date) =>
                                    setEventData({
                                      ...eventdata,
                                      end_date: date,
                                    })
                                  }
                                  placeholderText="End Date"
                                  onFocus={() => setIsEndDateCalendarOpen(true)}
                                  onBlur={() => setIsEndDateCalendarOpen(false)}
                                  open={isEndDateCalendarOpen}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-sm-6">
                                <select
                                  // className="form-select form-select-lg mb-3"
                                  className={`form-select form-select-lg mb-3 ${
                                    errorFields.includes("event_type")
                                      ? "error"
                                      : ""
                                  }`}
                                  aria-label=".form-select-lg example"
                                  name="event_type"
                                  value={eventdata.event_type}
                                  onChange={handleChange}
                                >
                                  <option defaultValue>
                                    Select Event Type
                                  </option>
                                  <option value="Free">Free</option>
                                  <option value="Paid">Paid</option>
                                </select>
                                {/* <input
                                  type="text"
                                  className={`form-control ${
                                    errorFields.includes("event_type")
                                      ? "error"
                                      : ""
                                  }`}
                                  placeholder="event_type"
                                  aria-label="event_type"
                                  name="event_type"
                                  value={eventdata.event_type}
                                  onChange={handleChange}
                                /> */}
                              </div>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  // className="form-control"
                                  className={`form-control ${
                                    errorFields.includes("event_entry_fee")
                                      ? "error"
                                      : ""
                                  }`}
                                  placeholder="event_entry_fee"
                                  aria-label="event_entry_fee"
                                  name="event_entry_fee"
                                  value={eventdata.event_entry_fee}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                          <Button
                            type="button"
                            variant="primary"
                            className="btn next-step"
                            onClick={nextStep}
                          >
                            Next
                          </Button>
                        </>
                      )}
                    </div>

                    {/* <div className="step step-2"> */}
                    <div
                      className={`step step-2 ${
                        currentStep === 2 ? "animate__fadeInRight" : ""
                      }`}
                    >
                      {currentStep === 2 && (
                        <>
                          <div className="mb-3">
                            <h3>Step 2</h3>
                            <div className="row">
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errorFields.includes("contact_person_name")
                                      ? "error"
                                      : ""
                                  }`}
                                  placeholder="contact_person_name"
                                  aria-label="contact_person_name"
                                  name="contact_person_name"
                                  value={eventdata.contact_person_name}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errorFields.includes("contact_number")
                                      ? "error"
                                      : ""
                                  }`}
                                  placeholder="contact_number"
                                  aria-label="contact_number"
                                  name="contact_number"
                                  value={eventdata.contact_number}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errorFields.includes("email") ? "error" : ""
                                  }`}
                                  placeholder="email"
                                  aria-label="email"
                                  name="email"
                                  value={eventdata.email}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errorFields.includes("address")
                                      ? "error"
                                      : ""
                                  }`}
                                  placeholder="address"
                                  aria-label="address"
                                  name="address"
                                  value={eventdata.address}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-sm-4">
                                <select
                                  // className=""
                                  className={`form-select form-select-lg mb-3 ${
                                    errorFields.includes("state") ? "error" : ""
                                  }`}
                                  aria-label=".form-select-lg example"
                                  name="state"
                                  value={eventdata.state}
                                  onChange={handleChange}
                                >
                                  <option defaultValue>Select State</option>
                                  {states.map((elm) => (
                                    <option key={elm.id} value={elm.id}>
                                      {elm.state_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-sm-4">
                                <select
                                  // className="form-select form-select-lg mb-3"
                                  className={`form-select form-select-lg mb-3 ${
                                    errorFields.includes("city") ? "error" : ""
                                  }`}
                                  aria-label=".form-select-lg example"
                                  name="city"
                                  value={eventdata.city}
                                  onChange={handleChange}
                                >
                                  <option defaultValue>city</option>
                                  {city.map((elm) => (
                                    <option key={elm.id} value={elm.id}>
                                      {elm.city_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-sm-4">
                                <select
                                  // className="form-select form-select-lg mb-3"
                                  className={`form-select form-select-lg mb-3 ${
                                    errorFields.includes("country")
                                      ? "error"
                                      : ""
                                  }`}
                                  aria-label=".form-select-lg example"
                                  name="country"
                                  value={eventdata.country}
                                  onChange={handleChange}
                                >
                                  <option defaultValue>country</option>
                                  <option value="1">India</option>
                                  <option value="2">Pakistan</option>
                                  <option value="3">Canada</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <Button
                            type="button"
                            variant="primary"
                            className=" mx-2"
                            onClick={nextStep}
                          >
                            Next
                          </Button>
                          <Button
                            type="button"
                            variant="secondary"
                            // className="btn  prev-step "
                            onClick={prevStep}
                          >
                            Previous
                          </Button>
                          {/* </div> */}
                        </>
                      )}
                    </div>

                    {/* <div className="step step-3"> */}
                    <div
                      className={`step step-3 ${
                        currentStep === 3 ? "animate__fadeInRight" : ""
                      }`}
                    >
                      {currentStep === 3 && (
                        <>
                          <div className="mb-3">
                            <h3>Step 3</h3>
                            <div className="row">
                              <div className="col-sm-6">
                                <textarea
                                  // className="form-control"
                                  className={`form-control ${
                                    errorFields.includes("short_description")
                                      ? "error"
                                      : ""
                                  }`}
                                  id="exampleFormControlTextarea1"
                                  placeholder="short_description"
                                  name="short_description"
                                  value={eventdata.short_description}
                                  onChange={handleChange}
                                  rows="1"
                                ></textarea>
                              </div>
                              <div className="col-sm-6">
                                <textarea
                                  // className="form-control"
                                  className={`form-control ${
                                    errorFields.includes("long_description")
                                      ? "error"
                                      : ""
                                  }`}
                                  id="exampleFormControlTextarea1"
                                  placeholder="long_description"
                                  name="long_description"
                                  value={eventdata.long_description}
                                  onChange={handleChange}
                                  rows="1"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <input
                                  type="file"
                                  className="form-control"
                                  name="files"
                                  id="fileInput"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <Button
                          variant="secondary"
                            type="button"
                            // className="btn prev-step"
                            onClick={prevStep}
                          >
                            Previous
                          </Button>

                          {/* </div> */}
                          <Modal.Footer>
                            <Button variant="secondary" onClick={()=>setShowModal(false)} >
                              Close
                            </Button>
                            <Button variant="primary"   onClick={handleSubmit}>
                              Save Changes
                            </Button>
                            {/* </div> */}
                          </Modal.Footer>
                        </>
                      )}
                    </div>
                  </form>
                </div>
                {/* </div> */}
              </Modal.Body>
            {/* </div> */}
          {/* </div> */}
        </Modal>
        {/* </div> */}
      </div>
    </div>
  );
};

export default EventForm;
