import React, { useState, useEffect } from "react";
import Login from "./Login";
import Signup from "./Signup";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import { Helmet } from "react-helmet";

const Authentication = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(true);
  const [isSignupFormOpen, setIsSignupFormOpen] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpActive(true);
    document.title = "Tricity ~ Signup";
  };

  const handleLoginClick = () => {
    setIsSignUpActive(false);
    document.title = "Tricity ~ Login";
  };
  // useEffect(() => {
  //   // Update the document title based on the current form
  //   document.title = isLoginFormOpen ? "Tricity ~ Login" : "Tricity ~ Signup";
  // }, [isLoginFormOpen]);
  useEffect(() => {
    // Add an event listener to detect changes in screen width
    function handleResize() {
      setIsMobileView(window.innerWidth <= 920); // Adjust the width as needed
    }

    // Initial check on component mount
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleLogin = () => {
    setIsLoginFormOpen(true);
    setIsSignupFormOpen(false);
    document.title = "Tricity ~ Login";
  };

  const toggleSignup = () => {
    setIsLoginFormOpen(false);
    setIsSignupFormOpen(true);
    document.title = "Tricity ~ Signup";
  };
 
  return (
    <>
      <Helmet>
        <title>Tricity ~ login</title>
      </Helmet>
      <Header />

      {isMobileView ? (
        <div className="form-modal">
          <div className="form-toggle">
            <button
              id="login-toggle"
              onClick={toggleLogin}
              style={{
                backgroundColor: isLoginFormOpen ? "#000" : "#fff",
                color: isLoginFormOpen ? "#fff" : "#222",
              }}
            >
              log in
            </button>
            <button
              id="signup-toggle"
              onClick={toggleSignup}
              style={{
                backgroundColor: isSignupFormOpen ? "#000" : "#fff",
                color: isSignupFormOpen ? "#fff" : "#222",
              }}
            >
              sign up
            </button>
          </div>
          <div
            id="login-form"
            style={{ display: isLoginFormOpen ? "block" : "none" }}
          >
            <Login />
          </div>
          <div
            id="signup-form"
            style={{ display: isSignupFormOpen ? "block" : "none" }}
          >
            <Signup />
          </div>
        </div>
      ) : (
        <section className="login_signup">
          <img src="" alt="" />
          <div
            className={`container sign_login_form ${
              isSignUpActive ? "right-panel-active" : ""
            }`}
            id="container"
          >
            <div className="form-container sign-up-container">
              <Signup />
            </div>
            <div className="form-container sign-in-container">
              <Login />
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p className="sign_login_contants">
                    To keep connected with us, please login with your personal
                    info
                  </p>
                  <button
                    className="ghost"
                    id="signIn"
                    onClick={handleLoginClick}
                  >
                    Login In
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p className="sign_login_contants">
                    Enter your personal details and start a journey with us
                  </p>
                  <button
                    className="ghost"
                    id="signUp"
                    onClick={handleSignUpClick}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default Authentication;
