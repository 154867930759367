import React from 'react'

const ListedItems = () => {
  return (
    <>
        <section className="listing_wraper" id="explore">
      <h2 className="main-title">What Do You Need Today?</h2>

      <div className="listed_items">
        <div className="content">
          <a href="#">
            <div className="content-overlay"></div>
            <img
              className="content-image"
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/location-image-1.png"
              alt=""
            />
            <div className="content-details fadeIn-bottom">
              <h3 className="content-title">Tour</h3>
            </div>
          </a>
        </div>
      </div>

      <div className="listed_items">
        <div className="content">
          <a href="#">
            <div className="content-overlay"></div>
            <img
              className="content-image"
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/location-image-2.png"
              alt=""
            />
            <div className="content-details fadeIn-top">
              <h3>Eats</h3>
            </div>
          </a>
        </div>
      </div>

      <div className="listed_items">
        <div className="content">
          <a href="#">
            <div className="content-overlay"></div>
            <img
              className="content-image"
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/location-image-3.png"
              alt=""
            />
            <div className="content-details fadeIn-left">
              <h3>Relex</h3>
            </div>
          </a>
        </div>
      </div>

      <div className="listed_items">
        <div className="content">
          <a href="#">
            <div className="content-overlay"></div>
            <img
              className="content-image"
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/location-image-4.png"
              alt=""
            />
            <div className="content-details fadeIn-right">
              <h3>Buy</h3>
            </div>
          </a>
        </div>
      </div>

      <div className="listed_items">
        <div className="content">
          <a href="#">
            <div className="content-overlay"></div>
            <img
              className="content-image"
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/location-image-4.png"
              alt=""
            />

            <div className="content-details fadeIn-right">
              <h3>Other</h3>
            </div>
          </a>
        </div>
      </div>
    </section>
    </>
  )
}

export default ListedItems
