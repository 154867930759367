import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";
import Swal from "sweetalert2";
const Sidebar = ({isSidebarActive}) => {
  let isLogin = false;
  let x = Cookies.get("login");
  if (x !== undefined) {
    isLogin = true;
  } else {
    isLogin = false;
  }
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out?",
      icon: "warning",
      allowOutsideClick:false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log out!"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user-data");
        document.cookie = "login=false;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        window.location.replace("/login");
     
      }
    });
  };
  return (
    <>
     <div
        className={`side-navbar d-flex justify-content-between flex-wrap flex-column ${
          isSidebarActive ? 'active-nav ' : ''
        }`}
        id="sidebar"
      >
      <ul className="nav flex-column text-white w-100">
          <Link to="/dashboard">
            <li href="" className="nav-link">
              <i className="fa fa-home" aria-hidden="true"></i>
              <span className="mx-2"> Dashborad</span>
            </li>
          </Link>
          <Link to="/events">
            <li  className="nav-link">
                {/* <Link ></Link> */}
              <i className="fa fa-calendar" aria-hidden="true"></i>
              <span className="mx-2">Your Events</span>
            </li>
          </Link>
          <a href="events_forms.html">
          <li href="#" className="nav-link">
            <i className="fa fa-gift" aria-hidden="true"></i>
            <span className="mx-2">Form </span>
          </li>
        </a>
        <Link onClick={handleLogout} >
          <li href="#" className="nav-link" >
            <i className="fa fa-sign-out" aria-hidden="true"></i>
            <span className="mx-2">Log Out</span>
          </li>
        </Link>
        </ul>
      </div> 
    </>
  )
}

export default Sidebar