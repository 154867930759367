import React from 'react'
import Header from '../../Layouts/Header'
import Sliders from '../Listing/Sliders'
import PlacesCarousel from './PlacesCarousel'
import ContactForm from '../ContactUs/ContactForm'
import Footer from '../../Layouts/Footer'
import ListedItems from './ListedItems'
import Reviews from './Reviews'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <>
    <Helmet>
      <title>Tricity ~ Home</title>
    </Helmet>
    <Header/>
         
    {/* <!-- Hero --> */}
 
    <div className="mask" style={{background: `url(assets/images/header_home.png)`, objectFit: "contain",marginTop:"3rem"}}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-white " style={{width: "90%", padding: "9em 0em 9em 0em"}}>
          <h1 className="creative_heading" data-text="Creative....">Creative....</h1>
          <p className="mb-3">Phasellus non nulla justo. Ut sollicitudin blandit nulla, quis pharetra tortor feugiat quis. 
          Donec rhoncus, enim <br/>nec rhoncus hendrerit, mi mi euismod ligul ed sollicitudin ex purus vel orci. Aenean id libero pharetra</p>
          
          <div className="row form_section">
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="What are You Looking For ?"
                  aria-label="City"
                />
              </div>
              <div className="col-sm-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  aria-label="State"
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip"
                  aria-label="Zip"
                />
              </div>
              <div className="col-sm-3">
                <button className="search_form">
                  <i className="fa fa-search" style={{fontSize:"24px"}}></i>Search
                </button>
              </div> 
            </div> 
        </div>
      </div>
      </div>
      {/* </div> */}
      {/* <!-- Hero --> */}
    <Sliders/>
    <PlacesCarousel/>
    <ListedItems/>
    <Reviews/>
    <ContactForm/>
    <Footer/>
    </>
  )
}

export default Home
