import axios from 'axios'
const config = require('../config')

const getAccessToken = () => {
    const userData = localStorage.getItem('user-data');
    const parsedData = JSON.parse(userData);
  
    if (parsedData && parsedData.success && parsedData.success.token) {
      const accessToken = parsedData.success.token;
      console.log("Token:", accessToken);
      return accessToken;
    } else {
      console.error("Token not found in local storage.");
      return null;
    }
  };
  
  const api = axios.create({
    baseURL: config.api_url + '/api',
    headers: {
      'Authorization': 'Bearer ' + getAccessToken(),
    }
  });

export const usersignup = payload => api.post('/signup', payload)
export const signupOtpVerify = payload => api.post('/signup/verification', payload)
export const userlogin = payload => api.post('/login', payload)
export const loginOtpVerify = payload => api.post('/login/verification', payload)
export const resendOtp = payload => api.post('/resendotp', payload)
export const contactUs = payload => api.post('/contact', payload)

// Dashboard Api
export const addEvents = payload => api.post('/events', payload)
export const addEventImages = payload => api.post('/eventgallery', payload)
export const getStates = payload => api.get('/state/index',)
export const getCity = payload => api.get('/city/index',)
export const getCategory = payload => api.get(`/category/index/${payload}`,)
export const getEvents = payload => api.get("/events/index",)
export const deleteEvents = id => api.delete(`/events/delete/${id}`)

const Apis ={
    usersignup,
    signupOtpVerify,
    userlogin,
    loginOtpVerify,
    resendOtp,
    contactUs,
    addEvents,
    getStates,
    getCity,
    getCategory,
    getEvents,
    addEventImages,
    deleteEvents
   
}
export default Apis