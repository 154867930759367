import React,{useState,useEffect} from 'react'
import Apis from "../../tricitiApis";
import { toast } from "react-toastify";

const Admin = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [category, setCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const[image,setImage]=useState([])
  const [showmodal, setShowModal] = useState(false);
  const [eventdata, setEventData] = useState({
    event_tittle: "",
    category_id: 0,
    sub_category: "",
    timing: "",
    short_description: "",
    long_description: "",
    contact_person_name: "",
    contact_number: "",
    email: "",
    address: "",
    state: "",
    city: "",
    country: "",
    start_date: "",
    end_date:  "",
    event_type: "",
    event_entry_fee: "",
    id: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrorFields([]);
    if (name === "category_id") {
      // Find the selected category in the response data
      const selectedCategory = category.find(
        (cat) => cat.id === parseInt(value, 10)
      );
      // Extract and set subcategories from the selected category
      if (selectedCategory && selectedCategory.get_category) {
        setSubCategories(selectedCategory.get_category);
      } else {
        setSubCategories([]);
      }
    }  
      // Handle other fields
      setEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(eventdata);
    setShowModal(true)
    if (validateCurrentStep()) {
      Apis.addEvents(eventdata)
        .then((response) => {
          if (response.data.success === true) {
                    toast.success(`${response.data.message}`);
                    console.log("events", response.data);
                    setErrorFields([]);
                    setEventData([])
                    setShowModal(false)
                  } else {
                    toast.error(`${response.data.errors.event_tittle}`);
                    console.log("events", response.data);
                  }
        })
        .catch((error) => {
          console.log(error);
        });
    }   
  }


  const displayStep = (stepNumber) => {
    if (stepNumber >= 1 && stepNumber <= 3) {
      // updateProgressBar();
    }
  };

  const nextStep = () => {
    if (validateCurrentStep()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };
  
  const validateCurrentStep = () => {
    const currentStepFields = getFieldsForCurrentStep(); // Implement this function
  
    const missingFields = currentStepFields.filter((field) => !eventdata[field]);
  
    if (missingFields.length > 0) {
      // Display an error message or handle the error in your preferred way
      setErrorFields(missingFields);
      toast.error('Please fill in all the required fields');
      return false;
    }
  
    return true;
  };
  const getFieldsForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return [
          "event_tittle",
          "category_id",
          "sub_category",
          "timing",
          "start_date",
          "end_date",
          "event_type",
          "event_entry_fee",
        ];
      case 2:
        return [
          "contact_person_name",
          "contact_number",
          "email",
          "address",
          "state",
          "city",
          "country",
        ];
      case 3:
        return [
          "short_description",
          "long_description",
          // "get_event_galleries_image", // You might want to add image validation here
        ];
      default:
        return [];
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return{
    eventdata,
    setEventData,
    currentStep,
    nextStep,
    prevStep,
    displayStep,
    handleChange,
    handleSubmit,
    category,
    setCategory,
    subCategories,
    errorFields,
    setImage,
    image,
    showmodal,
    setShowModal
  }
}

export default Admin
