import React from 'react'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import { Helmet } from 'react-helmet'

const Discover = () => {
  return (
    <>
    <Helmet>
    <title>Tricity ~ Discover</title>
    </Helmet>
    <Header/>
    <div id="carousel slide"
      data-bs-ride="carousel"
      data-bs-pause="false"
    >
      <div className="carousel-inner">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#mainBanner"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#mainBanner"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#mainBanner"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#mainBanner"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#mainBanner"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div>
        <div className="carousel-item active" data-bs-interval="5000">
          <img
            src="https://pceventsinc.com/wp-content/uploads/LP-1.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption px-md-5">
            <h5>Chandighar Is Cooking</h5>
            <hr className="bg-light border-4 border-top border-light" />
            <p>Chandighar da swaad, coke de naal</p>
            <p>November 25 - November 26 | 1:30PM onwards</p>
            <div className="banner-btn">
              <a
                href="https://insider.in/cocacola-chandigarh-is-cooking-dec25-2023/event"
              >
                <button
                  type="button"
                  className="btn btn-secondary px-lg-5 px-md-4 px-2 py-2 rounded-0 border"
                >
                  More Details
                </button></a
              >
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="5000">
          <img
            src="https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_800/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1698261606%2Fukhzt97ndkhify2mmyfj.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption px-md-5">
            <h5>Babbu Maan Live <br/>Concert Panchkula</h5>
            <hr className="bg-light border-4 border-top border-light" />
            <p>Babbu Maan Live Concert Panchkula</p>
            <p>Sat 25 Nov 2023</p>
            <div className="banner-btn">
              <button
                type="button"
                className="btn btn-secondary px-lg-5 px-md-4 px-2 py-2 rounded-0 border"
              >
                More Details
              </button>
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="5000">
          <img
            src="https://www.mumbai77.com/images/newblog/Food-Stalls.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption px-md-5">
            <h5>
              Today Fairs & Mela <br />Religious Events
              in Kharar
            </h5>
            <hr className="bg-light border-4 border-top border-light" />
            <p>Today Fairs & Mela Religious Events in Kharar</p>
            <div className="banner-btn">
              <button
                type="button"
                className="btn btn-secondary px-lg-5 px-md-4 px-2 py-2 rounded-0 border"
              >
               More Details
              </button>
             
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="5000">
          <img
            src="https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_400/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1699351918%2Ftsfzipls2op8nqwebfmf.png"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption px-md-5">
            <h5>Punchliners Comedy Show ft<br/> Parvinder Singhin Chandigarh</h5>
            <hr className="bg-light border-4 border-top border-light" />
            <p>Elante Social, Chandigarh</p>
            <p>December 7 | 8PM</p>
            <div className="banner-btn">
              <button
                type="button"
                className="btn btn-secondary px-lg-5 px-md-4 px-2 py-2 rounded-0 border"
              >
                More Details
              </button>
              
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="5000">
          <img
            src="https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_800/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1699966943%2Fp2vx0etla9nli31qqzwh.png"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption px-md-5">
            <h5>Simba Uproar 2023 | Panchkula</h5>
            <hr className="bg-light border-4 border-top border-light" />
            <p>November 25 | 7PM onwards</p>
            <div className="banner-btn">
              <button
                type="button"
                className="btn btn-secondary px-lg-5 px-md-4 px-2 py-2 rounded-0 border"
              >
               More Details
              </button>
             
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <!---------------------- end slider ----------------> 
    <!---------------------  static tab ----------------> */}
    <section className="#"> 
      <div className="events_tabs">
        <a href="#" className="event_list">Today</a>
        <a href="#" className="event_list">Tomorrow</a>
        <a href="#" className="event_list">This Weeked</a>
        <a href="#" className="event_list">Next Weeked</a>
        <a href="#" className="event_list">Next 15 Days</a>
        <a href="#" className="event_list">Next 30 Days</a>
      </div> 
      <h2>filter events by Places</h2>  
<div className="container">
  <div className="row">
    
      <table className="dropdown-table">
        <thead>
          <tr className="filters">
            <th>
              <select id="assigned-region-filter" className="form-control">
                <option>Show All Events</option>
                <option>Show Mohali Events Only</option>
                <option>Show Chandighar Events Only</option>
                <option>Show Kharar Events Only</option>
                <option>Show Zirkpur Events Only</option>
              </select>
            </th>
          </tr>
        </thead>
      </table>   
      <table id="event-list-tbl" className="table event-table"> 
        <tbody> 
          <tr id="event-1"
              className="event-list-row" 
              data-task-id="1"
              data-date="APR 2020"
              data-region="Show Japan Events Only"
              data-tags="Tag 2">
            <td>25-26 NOV 2023</td>
            <td>CHANDIGHAR IS COOKING<br/>
              <span className="host">Hosted by MWR</span>
            </td>
            <td>Chandigarh<br/>
              <span className="jp">Chandigarh</span>
                </td>
          </tr> 
          <tr id="event-2"
              className="event-list-row" 
              data-task-id="2"
              data-date="APR 2020"
              data-region="Show Okinawa Events Only"
              data-tags="Tag 2">
            <td>25 Nov 2023</td>
            <td>BABBU MAAN LIVE CONCERT PANCHKULA<br/>
              <span className="host">Hosted by MCCS</span>
            </td>
            <td>Panchkula<br/>
              <span className="ok">OKINAWA</span>
                </td>
          </tr> 
          <tr id="event-3"
              className="event-list-row" 
              data-task-id="3"
              data-date="APR 2020"
              data-region="Show South Korea Events Only"
              data-tags="Tag 2">
            <td>Nov 2023</td>
            <td>TODAY FAIRS & MELA RELIGIOUS EVENTS
              IN KHARAR<br/>
              <span className="host">Hosted by Stars and Stripes</span>
            </td>
            <td>Kharar<br/>
              <span className="ko">Kharar</span>
                </td>
          </tr>  
          <tr id="event-4"
              className="event-list-row" 
              data-task-id="4"
              data-date="MAY 2020"
              data-region="Show Japan Events Only"
              data-tags="Tag 2">
            <td>15 Nov 2023</td>
            <td>INDIA vs NEW ZEALAND WORLD CUP SEMI-FINAL LIVE SCREENING<br/>
              <span className="host">Hosted by Navy Exchange</span>
            </td>
            <td>Mohali<br/>
              <span className="jp">MAINLAND JAPAN</span>
                </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
{/* </div>  */}
</section>
<Footer/>
    </>
  )
}

export default Discover
