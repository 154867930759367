import React, { useState } from "react";
import Apis from "../../../tricitiApis";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [formdata, SetFormData] = useState({
    name: "",
    email: "",
    company_name: "",
    message: "",
    isChecked: false,
  });

const[error,setError]=useState({
  name:false,
  email:false,
  company_name:false,
  message:false
})

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'check') {
      SetFormData({
        ...formdata,
        isChecked: event.target.checked
      });
    } else {
      SetFormData({
        ...formdata,
        [name]: value,
      });
    }
    setError({
      ...error,
      [name]: false,
    })
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formdata);
    if(!formdata.name || !formdata.email || !formdata.company_name || !formdata.message){
      toast.error(`All fields are required`)
      setError({
        name: !formdata.name,
        email:!formdata.email,
        company_name:!formdata.company_name,
        message:!formdata.message
      })
      return
    }
    Apis.contactUs(formdata)
      .then((res) => {
        if (res.data.message === "Validation errors") {
          toast.error(
            `${
              res.data.errors.name ||
              res.data.errors.email ||
              res.data.errors.company_name ||
              res.data.errors.message
            }`
          )
          setError({
            name:res.data.errors.name? true:false,
            email: res.data.errors.email ? true : false,
            company_name: res.data.errors.company_name ? true : false,
            message: res.data.errors.message ? true : false,  

          })
          ;
        } else {
          toast.success(`${res.data.message}`);
          SetFormData({
            name: "",
            email: "",
            company_name: "",
            message: "",
          });
        }
        console.log("response", res);
      })
      .catch((error) => {
        // console.log("error",error)
        toast.error(`{error}`)
      });
  };

  return (
    <>
    <section className="contact-us_form">
        <div className="contain">
          <div className="wrapper">
            <div className="form">
              <h4>GET IN TOUCH</h4>
              <h2 className="form-headline">Send us a message</h2>
              <form id="submit-form" action="">
                <p>
                  <input
                    id="name"
                    // className="form-input"
                    type="text"
                    placeholder="Your Name*"
                    name="name"
                    value={formdata.name}
                    onChange={handleChange}
                    className={error.name ? "error form-input" : "form-input" }
                  />
                </p>
                <p>
                  <input
                    id="email"
                    // className="form-input"
                    className={error.email ? "error form-input" : "form-input" }
                    type="email"
                    placeholder="Your Email*"
                    name="email"
                    value={formdata.email}
                    onChange={handleChange}
                  />
                </p>
                <p className="full-width">
                  <input
                    id="company-name"
                    // className="form-input"
                    className={error.company_name ? "error form-input" : "form-input" }
                    type="text"
                    placeholder="Company Name*"
                    name="company_name"
                    value={formdata.company_name}
                    onChange={handleChange}
                
                  />
                </p>
                <p className="full-width">
                  <textarea
                    maxLength={150}
                    // id="message"
                    // cols="30"
                    // rows="7"
                    className={error.message ? "error form-input" : "form-input" }
                    placeholder="Your Message*"
                    name="message"
                    value={formdata.message}
                    onChange={handleChange}
                  
                  ></textarea>
                </p>
                <p>
                  <input type="checkbox" id="checkbox" name="check"
                   checked={formdata.isChecked}
                   onChange={handleChange} />
                  Yes, I would like to receive communications by call / email
                  about Company's services.
                </p>
                <p>
                  <button className="submint-btn" onClick={handleSubmit}>
                    Submit
                  </button>
                </p>
              </form>
            </div>


            <div className="contacts contact-wrapper">
              <div className="global_contact">
                <img src="assets/images/cc1.png" alt="" className="contact_img" />
              </div>
              <p>
                We've driven online revenues of over
                <span className="highlight-text-grey">$2 billion</span> for our
                clients. Ready to know how we can help you?
              </p>
              <div className="hightlight-contact-info">
                <div className="email-info">
                  <i
                    className="fa fa-envelope"
                    aria-hidden="true"
                    style={{ color: "black" }}
                  ></i>
                  info@demo.com
                  <br />
                  <i
                    className="fa fa-phone"
                    aria-hidden="true"
                    style={{ color: "black" }}
                  ></i>
                  <span className="highlight-text">+91 11 1111 2900</span>
                </div>
              </div>
              {/* <!--Google map--> */}
              <div
                id="map-container-google-1"
                className="z-depth-1-half map-container"
                style={{ height: "200px" }}
              >
                <iframe
                title="google map"
                  src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  style={{ border: "0" }}
                  // allowFullScreen
                ></iframe>
              </div>

              {/* <!--Google Maps--> */}
            </div>
</div>
</div>
</section>
          
         
    </>
  );
};

export default ContactForm;
