import React from 'react'
import Header from '../../Layouts/Header'
import ContactForm from './ContactForm'
import Footer from '../../Layouts/Footer'
import { Helmet } from 'react-helmet'

const ContactUs = () => {
  return (
    <>
    <Helmet>
    <title>Tricity ~ Contact Us</title>
    </Helmet>
      <Header/>
      <section className="contact_sec">
      <div className="contact_img">
      <img
          src="https://cdn-jlikf.nitrocdn.com/YVbIixgxJWEKlKmSYULQtavDMCqnhGfi/assets/images/optimized/rev-a190e41/tricityltd.com/wp-content/uploads/2020/02/contact_banner.jpg"
          alt="contactus bg"
        />
      </div>
    </section>
      <ContactForm/>           
    <Footer/>

    </>
  )
}

export default ContactUs
