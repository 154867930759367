import React from 'react'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <>
    <Helmet>
    <title>Tricity ~ About</title>
    </Helmet>
    <Header/>
         <section className="about_sec">
      <div className="about_img">
        <img
          src="assets/images/about_us.png"
          alt=""
        />
      </div>
    </section>

    <section className="how_work">
      <h1 className="text-center mt-5">How we work</h1>
      <div className="how-section1">
        <div className="row">
          <div className="col-md-6 how-img">
            <img
              src="https://greenusys.com/wp-content/uploads/2023/03/25-Experience-01.webp"
              className="rounded-circle img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col">
                <h3>CHANDIGHAR</h3>
                <p>
                  We collaborate closely with you to gather detailed project
                  requirements and understand your goals.
                </p>
              </div>
              <div className="col">
                <h3>MOHALI</h3>
                <p>
                  Our team creates visually appealing designs and develops
                  robust software, apps, and websites using modern technologies.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3>KHARAR</h3>
                <p>
                  Rigorous testing ensures the highest level of quality,
                  including functional, performance, compatibility, and security
                  testing.
                </p>
              </div>
              <div className="col">
                <h3>ZIRKPUR</h3>
                <p>
                  We deploy the solution and provide ongoing support,
                  maintenance, and updates to ensure smooth operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="how-section1">
        <h1 className="text-center mt-5">We are the experts in</h1>
        <div className="row">
          <div className="col">
            <h3>Requirement Gathering</h3>
            <p>
              We collaborate closely with you to gather detailed project
              requirements and understand your goals.
            </p>
          </div>
          <div className="col">
            <h3>Planning and Strategy</h3>
            <p>
              Our team creates visually appealing designs and develops robust
              software, apps, and websites using modern technologies.
            </p>
          </div>
          <div className="col">
            <h3>Planning and Strategy</h3>
            <p>
              Our team creates visually appealing designs and develops robust
              software, apps, and websites using modern technologies.
            </p>
          </div>
          <div className="col">
            <h3>Planning and Strategy</h3>
            <p>
              Our team creates visually appealing designs and develops robust
              software, apps, and websites using modern technologies.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h3>Requirement Gathering</h3>
            <p>
              We collaborate closely with you to gather detailed project
              requirements and understand your goals.
            </p>
          </div>
          <div className="col alginment">
            <h3>Planning and Strategy</h3>
            <p>
              Our team creates visually appealing designs and develops robust
              software, apps, and websites using modern technologies.
            </p>
          </div>
          <div className="col">
            <h3>Planning and Strategy</h3>
            <p>
              Our team creates visually appealing designs and develops robust
              software, apps, and websites using modern technologies.
            </p>
          </div>
          <div className="col">
            <h3>Planning and Strategy</h3>
            <p>
              Our team creates visually appealing designs and develops robust
              software, apps, and websites using modern technologies.
            </p>
          </div>
        </div>
      </div>
    </section>

  
    <section className="our_team">
      <h1 className="team_heading">Get to know our team</h1>
      <p>
        Caecenas at quam egestas, vestibulum odio id, coonv allis nisl nec
        tempor. Donec consectetur dape.
      </p>
      <div className="team_innersec">
        <div className="team_lwr">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/team3.webp"
            alt=""
          />
          <h4>Johnson</h4>
          <p>CEO</p>
        </div>
        <div className="team_lwr">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/team1.webp"
            alt=""
          />
          <h4>Johnson</h4>
          <p>CEO</p>
        </div>
        <div className="team_lwr">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/team2.webp"
            alt=""
          />
          <h4>Johnson</h4>
          <p>CEO</p>
        </div>
        <div className="team_lwr">
          <img
            src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/team4.webp"
            alt=""
          />
          <h4>Johnson</h4>
          <p>CEO</p>
        </div>
      </div>
    </section>
    <div className="learn_more">
      <h2>View Reviews for Us to learn more</h2>
      <p>
        Curabitur nisi quam, venenatis ac ipsum et, dictum pellente ue lacus.
      </p>
    </div> 
    <Footer/>
    </>
  )
}

export default About
