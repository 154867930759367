import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
       <footer>
      <div className="footer_section">
        <div className="footer_lwr">
          <i className="fa fa-balance-scale" style={{fontSize: "36px"}}></i>
        <Link to='/terms'> <p className="term_policy">Terms of Use</p></Link>
        </div>
        <div className="footer_lwr">
          <i className="fa fa-asl-interpreting" style={{fontSize: "36px"}}></i>
          <Link to='/policy'><p className="term_policy">Privacy & Policy</p></Link>
        </div>
        <div className="footer_lwr footer_navbar">
          <div className="menu_ff"></div>
          <div className="menu_ff"></div>
          <div className="menu_ff"></div>
          <ul className="list_footer">
            <Link to="/">
            <li>Home</li></Link>
            <div className="vl"></div>
            <Link to="/about">
            <li>About</li></Link>
            <div className="vl"></div>
            <Link to="/login">
            <li>Sign In</li></Link>
          </ul>
        </div>
        <div className="footer_lwr">
          <i className="fa fa-facebook-f" style={{fontSize: "36px"}}></i
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <i className="fa fa-instagram" style={{fontSize: "36px"}}></i
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <i className="fa fa-linkedin-square" style={{fontSize: "36px"}}></i
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <i className="fa fa-twitter" style={{fontSize: "36px"}}></i>
        </div>
      </div>

      <div className="footer_imgicons">
        <img src="http://wt.ax/city-listing/assets/images/logo.png" alt="" />
        <p className="footer_lwr_text">
        
          Copyright &copy; {(new Date().getFullYear())} All Rights Reserved by Codeadda Pvt. Ltd.<br />
        </p>
      </div>
    </footer>
    </>
  )
}

export default Footer
