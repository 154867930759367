import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import store from './redux/store';
// import { Provider } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//     <Provider store={store}>
//  </Provider> 

    <App />

);

