import React,{useState} from 'react'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'
// import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
const Dashboard = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(true);
    const sidebarToggle=()=>{
      setIsSidebarActive(!isSidebarActive)
    }
  return (
      <div>
        <Header/>
        <section className="listing_sidenav">
        <Sidebar sidebarToggle={sidebarToggle}
        isSidebarActive={isSidebarActive}
        setIsSidebarActive={setIsSidebarActive}
        />
      {/* <!-- Main Wrapper --> */}
      <div
        className={`p-1 my-container ${
          isSidebarActive ? 'active-cont ' : ''
        }`}
      >
        {/* <!-- Top Nav --> */}

        {/* <!--End Top Nav --> */}
        <div className="container">
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className="icons_wraper_dashborad">
                <nav className="navbar top-navbar navbar-light">
                  <a className="btn border-0" id="menu-btn"
                    ><i className="bx bx-menu" onClick={sidebarToggle}></i
                  ></a>
                </nav>
              </div>
              <div className="page-title-heading">
                <h1>Analytics Dashboard</h1>
                <p className="page-title-subheading">
                  This is an example dashboard created using build-in elements
                  and components.
                </p>
              </div> 
            </div>
            <div className="container row">
              <div className="col-md-4">
                  <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="widget-content-wrapper text-white">
                          <div className="widget-content-left">
                              <div className="widget-heading">Total Orders</div>
                              <div className="widget-subheading">Last year expenses</div>
                          </div>
                          <div className="widget-content-right">
                              <div className="widget-numbers text-white"><span>1896</span></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="widget-content-wrapper text-white">
                          <div className="widget-content-left">
                              <div className="widget-heading">Clients</div>
                              <div className="widget-subheading">Total Clients Profit</div>
                          </div>
                          <div className="widget-content-right">
                              <div className="widget-numbers text-white"><span>$ 568</span></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="card mb-3 widget-content bg-grow-early">
                      <div className="widget-content-wrapper text-white">
                          <div className="widget-content-left">
                              <div className="widget-heading">Followers</div>
                              <div className="widget-subheading">People Interested</div>
                          </div>
                          <div className="widget-content-right">
                              <div className="widget-numbers text-white"><span>46%</span></div>
                          </div>
                      </div>
                  </div>
              </div>  
          </div>
          <div className="row graph_treesection">
            <div className="col-lg-8 text-white graph__baar bg-white">
              <h5 className="text-left graph_chart">
                LAST 7 DAYS COMMISSION EARNING
              </h5>

              <canvas id="myChart"></canvas>
            </div> 
            <div className="col-lg-4">
              <div className="circle-wrap firstPercentage">
                <div className="circle">
                  <div className="mask full">
                    <div className="fill"></div>
                  </div>
              
                  <div className="mask half">
                    <div className="fill"></div>
                  </div>
              
                  <div className="inside-circle">
                    <h3><span className="count">85</span>%</h3>
                    <h4>Air Dried</h4>
                  </div> 
                </div> 
              </div>  
            </div> 
            
          </div>
          <div className="container row bg-white mt-4">
            <div className="card-header">Active Users
              <div className="btn-actions-pane-right">
                  <div role="group" className="btn-group-sm btn-group">
                      <button className="active btn btn-focus">Last Week</button>
                      <button className="btn btn-focus">All Month</button>
                  </div>
              </div>
          </div>
          <div className="table-responsive">
            <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th>Name</th>
                    <th className="text-center">City</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="text-center text-muted">#345</td>
                    <td>
                        <div className="widget-content p-0"> 
                                <div className="widget-content-left flex2">
                                    <div className="widget-heading">John Doe</div>
                                    <div className="widget-subheading opacity-7">Web Developer</div>
                                </div>
                          </div> 
                    </td>
                    <td className="text-center">Madrid</td>
                    <td className="text-center">
                        <div className="badge badge-warning">Pending</div>
                    </td>
                    <td className="text-center">
                        <button type="button" id="PopoverCustomT-1" className="btn btn-primary btn-sm">Details</button>
                    </td>
                </tr>
                <tr>
                    <td className="text-center text-muted">#347</td>
                    <td>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                               
                                <div className="widget-content-left flex2">
                                    <div className="widget-heading">Ruben Tillman</div>
                                    <div className="widget-subheading opacity-7">Etiam sit amet orci eget</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="text-center">Berlin</td>
                    <td className="text-center">
                        <div className="badge badge-success">Completed</div>
                    </td>
                    <td className="text-center">
                        <button type="button" id="PopoverCustomT-2" className="btn btn-primary btn-sm">Details</button>
                    </td>
                </tr>
                <tr>
                    <td className="text-center text-muted">#321</td>
                    <td>
                        <div className="widget-content p-0"> 
                                <div className="widget-content-left flex2">
                                    <div className="widget-heading">Elliot Huber</div>
                                    <div className="widget-subheading opacity-7">Lorem ipsum dolor sic</div>
                                </div>
                            </div>
                        {/* </div> */}
                    </td>
                    <td className="text-center">London</td>
                    <td className="text-center">
                        <div className="badge badge-danger">In Progress</div>
                    </td>
                    <td className="text-center">
                        <button type="button" id="PopoverCustomT-3" className="btn btn-primary btn-sm">Details</button>
                    </td>
                </tr>
                <tr>
                    <td className="text-center text-muted">#55</td>
                    <td>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                             
                                <div className="widget-content-left flex2">
                                    <div className="widget-heading">Vinnie Wagstaff</div>
                                    <div className="widget-subheading opacity-7">UI Designer</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="text-center">Amsterdam</td>
                    <td className="text-center">
                        <div className="badge badge-info">On Hold</div>
                    </td>
                    <td className="text-center">
                        <button type="button" id="PopoverCustomT-4" className="btn btn-primary btn-sm">Details</button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="d-block text-center card-footer"> 
              <button className="btn-wide btn btn-success">Save</button>
              <button className="btn-wide btn btn-success">Reset</button>
          </div>
        </div>  
        </div> 
      </div> 
     </div> 
    </div>
    </section>
    <Footer/>
    </div>
  )
}

export default Dashboard
