import React,{useState} from 'react'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import Dropdown from 'react-bootstrap/Dropdown';
import { Helmet } from 'react-helmet';
// import Sidebar from './Sidebar';
const Listing = () => {
  return (
    <>
    <Helmet>
      <title>Tricity ~ Listing </title>
    </Helmet>
     <Header/>
     <section className="listing_sidenav">
    

    {/* <!-- Main Wrapper --> */}
    <div className="p-1 my-container ">
      {/* <!-- Top Nav --> */}
   
      {/* <!--End Top Nav --> */}
      <div className="container bg-white">
          <nav className="navbar navbar-expand-md navbar-light bg-white">
            <div className="container-fluid p-0">
              <a className="navbar-brand text-uppercase fw-800" href="#"
                ><span className="border-red pe-2">New</span>Product</a
              >
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#myNav"
                aria-controls="myNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="fa fa-bars"></span>
              </button>
              <div className="collapse navbar-collapse" id="myNav">
                <div className="navbar-nav ms-auto">
                  <a className="nav-link active" aria-current="page" href="#"
                    >All</a
                  >
                  <a className="nav-link" href="#">Shop</a>
                  <a className="nav-link" href="#">visited</a>
                  <a className="nav-link" href="#">Contact</a>
                  <a className="nav-link" href="#">Add Listing</a>
                  <a className="nav-link" href="#">blog</a>
                </div>
              </div>
            </div>
          </nav>
          <div className="row">
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/3186654/pexels-photo-3186654.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                  className="d-flex align-items-center justify-content-center list-unstyled icons"
                >
                  <li className="icon">
                    <i className="fa fa-heart-o"></i>
                  </li>
                  <li className="icon mx-3">
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                  <li className="icon">
                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>

              <div className="title pt-4 pb-1">Restrurent</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
 
            </div>
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/11198237/pexels-photo-11198237.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                className="d-flex align-items-center justify-content-center list-unstyled icons"
              >
                <li className="icon">
                  <i className="fa fa-heart-o"></i>
                </li>
                <li className="icon mx-3">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                <li className="icon">
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </li>
              </ul>
              </div>

              <div className="title pt-4 pb-1">Hospital</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
               
            </div>
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/238622/pexels-photo-238622.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                  className="d-flex align-items-center justify-content-center list-unstyled icons"
                >
                  <li className="icon">
                    <i className="fa fa-heart-o"></i>
                  </li>
                  <li className="icon mx-3">
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                  <li className="icon">
                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>

              <div className="title pt-4 pb-1">Travelling</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
              
            </div>
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/2388569/pexels-photo-2388569.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                className="d-flex align-items-center justify-content-center list-unstyled icons"
              >
                <li className="icon">
                  <i className="fa fa-heart-o"></i>
                </li>
                <li className="icon mx-3">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                <li className="icon">
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </li>
              </ul>
              </div>
              <div className="title pt-4 pb-1">Stdio</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
               
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/5490965/pexels-photo-5490965.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                  className="d-flex align-items-center justify-content-center list-unstyled icons"
                >
                  <li className="icon">
                    <i className="fa fa-heart-o"></i>
                  </li>
                  <li className="icon mx-3">
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                  <li className="icon">
                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>

              <div className="title pt-4 pb-1">Pub & Bar</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
               
            </div>
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/4393021/pexels-photo-4393021.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                className="d-flex align-items-center justify-content-center list-unstyled icons"
              >
                <li className="icon">
                  <i className="fa fa-heart-o"></i>
                </li>
                <li className="icon mx-3">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                <li className="icon">
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </li>
              </ul>
              </div>

              <div className="title pt-4 pb-1">Food</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
              
            </div>
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/1552249/pexels-photo-1552249.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                  className="d-flex align-items-center justify-content-center list-unstyled icons"
                >
                  <li className="icon">
                    <i className="fa fa-heart-o"></i>
                  </li>
                  <li className="icon mx-3">
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                  <li className="icon">
                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="title pt-4 pb-1">Fitness & Gym</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
               
            </div>
            <div
              className="col-lg-3 col-sm-6 d-flex flex-column align-items-center justify-content-center product-item my-3"
            >
              <div className="product">
                <img
                  src="https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <ul
                className="d-flex align-items-center justify-content-center list-unstyled icons"
              >
                <li className="icon">
                  <i className="fa fa-heart-o"></i>
                </li>
                <li className="icon mx-3">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                <li className="icon">
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </li>
              </ul>
              </div>
              <div className="tag bg-green">Comming Soon</div>
              <div className="title pt-4 pb-1">Hotel & Stay</div>
              <div className="d-flex align-content-center justify-content-center">
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
             
            </div>
          </div>
        </div>
    </div> 
  </section>
    {/* // <!-----------------------------------listing sec2---------------------> */}
       <section className="section_listing">
        <div className="trending_now">
          <h1>Trending Right Now</h1>
          <p>The most quoted recently</p>
        </div>
        <div className="tranding_wraper">
          <div className="listed_items">
            <div className="content">
              <a href="#">
                <div className="content-overlay" style={{borderRadius: "137px"}}></div>
                <img
                  className="content-image"
                  src="https://wt.ax/city-listing/assets/images/trending/2.jpg"
                  alt=""
                  style={{borderRadius: "137px"}}
                />
                <div className="content-details fadeIn-top">
                  <h3>Chandighar</h3>
                </div>
              </a>
            </div>
            <p className="treanding_lwr">3014 20th St San Francisco</p>
            <p className="treanding_para2">
              The love of coffee shines all the woody ambience
            </p>
          </div>
          <div className="listed_items">
            <div className="content">
              <a href="#">
                <div className="content-overlay" style={{borderRadius: "137px"}}></div>
                <img
                  className="content-image"
                  src="https://wt.ax/city-listing/assets/images/trending/3.jpg"
                  alt=""
                  style={{borderRadius: "137px"}}
                />
                <div className="content-details fadeIn-top">
                  <h3>Mohali</h3>
                </div>
              </a>
            </div>
            <p className="treanding_lwr">3014 20th St San Francisco</p>
            <p className="treanding_para2">The New Orleans Museum of Art</p>
          </div>
          <div className="listed_items">
            <div className="content">
              <a href="#">
                <div className="content-overlay" style={{borderRadius: "137px"}}></div>
                <img
                  className="content-image"
                  src="https://wt.ax/city-listing/assets/images/trending/1.jpg"
                  alt=""
                  style={{borderRadius: "137px"}}
                />
                <div className="content-details fadeIn-top">
                  <h3>Kharar</h3>
                </div>
              </a>
            </div>
            <p className="treanding_lwr">3014 20th St San Francisco</p>
            <p className="treanding_para2">
              48-acre urban oasis located on Orange County
            </p>
          </div>
          <div className="listed_items">
            <div className="content">
              <a href="#">
                <div className="content-overlay" style={{borderRadius: "137px"}}></div>
                <img
                  className="content-image"
                  src="https://wt.ax/city-listing/assets/images/trending/4.jpg"
                  alt=""
                  style={{borderRadius: "137px"}}
                />
                <div className="content-details fadeIn-top">
                  <h3>Zirkpur</h3>
                </div>
              </a>
            </div>
            <p className="treanding_lwr">3014 20th St San Francisco</p>
            <p className="treanding_para2">3014 20th St San Francisco</p>
          </div>
        </div>
      </section>
     <Footer/> 
    </>
  )
}

export default Listing
