import React from 'react'

const Reviews = () => {
  return (
    <>
         <section className="ranking_system">
      <div className="ranking_sec">
        <h3 className="raking_heading" id="timing_counts">
          Plan Vacation to the Fullest
        </h3>
        <p className="ranking-text">
          Nullam vehicula ipsum a arcu cursus vitae congue. In egestas erat
          imperdiet sed euismod. Lacus laoreet non curabitur gravida arcu ac
          tortor dignissim.
        </p>
        <img
          src="https://jusdial.wpengine.com/wp-content/uploads/2023/03/home-1-wave-bg.png"
          alt=" "
          className="ranking_img"
        />
      </div>
      <div className="ranking_series">
        <div className="ranking_count">
          <h4 className="waviy">
            <span style={{i: "1"}}>0</span>
            <span style={{i: "2"}}>1</span>
          </h4>
          <h5 className="ranking_finds">Find a Place</h5>
          <p className="raking_contants">
            Fermentum et sollicitudin ac orci phasell.
          </p>
        </div>
        <div className="ranking_count">
          <h4 className="waviy">
            <span style={{i: "3"}}>0</span>
            <span style={{i: "4"}}>2</span>
          </h4>
          <h5 className="ranking_finds">Review Plans</h5>
          <p className="raking_contants">
            Rgestas maecenas at quam allis nisl nec tempor.
          </p>
        </div>
        <div className="ranking_count">
          <h4 className="waviy">
            <span style={{i: "4"}}>0</span>
            <span style={{i: "5"}}>3</span>
          </h4>
          <h5 className="ranking_finds">Book Today</h5>
          <p className="raking_contants">
            At varius vel pharetra vel turpis nunc eget lorem.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div className="container-fluid mt-5" id="Directory">
        <div className="row gy-4">
          {/* <!-- Flip Card --> */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://images.pexels.com/photos/1554654/pexels-photo-1554654.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                </div>

                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Pub & Bar</h5>
                    <h2>Drunken Bar</h2>
                    <p>
                      pooja singh
                      <br />
                      <b>nice place </b><br />Drunk house is a very cool place
                      where u cud get all type of foods. They have a very quick
                      service as well as food was also tasty. It is situated in
                      rajouri garden. They have a nice ambience and hospitality.
                      I have visited there..
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Pub & Bar<br /><b>Drunken Bar</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>

          {/* <!-- You can repeat the above structure for more flip cards and just change the random value in the image URL -->
          <!-- Flip Card --> */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://images.pexels.com/photos/269140/pexels-photo-269140.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                  
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Movies & Events</h5>
                    <h2>Akira – Anime</h2>
                    <p>
                      Avnir Kaur
                      <br />
                      <b>Huge Screens! </b><br />We went here to see 1917. The
                      ticket process was largely a self serve affair. Recommend
                      looking on the website first, as they often have special
                      deals and different types of showings at this theatre.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-1.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Movies & Events<br /><b>Akira – Anime</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>

          {/* <!-- 3rd -->
          <!-- Flip Card --> */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://images.pexels.com/photos/315403/pexels-photo-315403.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                 
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Destination</h5>
                    <h2>Dukemilan Stakes</h2>
                    <p>
                      Akshey Sharma
                      <br />
                      <b>Huge Screens! </b><br />"Nunc consequat non neque
                      tempus bibendum. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Donec porttitor, est vehicula pulvinar
                      maximus, nulla felis mollis tellus, eu luctus metus odio
                      neada mi eu ultrices "
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-3.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Destination<br /><b>Dukemilan Stakes</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>

          {/* <!-- 4th -->
          <!-- Flip Card --> */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://images.pexels.com/photos/13441993/pexels-photo-13441993.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                  
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Back Title</h5>
                    <p>Some text about this flip card.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-1.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Shoping<br />  <b>shoping</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>
  {/* <!-- 5th -->
          <!-- Flip Card --> */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://images.pexels.com/photos/5088180/pexels-photo-5088180.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                  
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Back Title</h5>
                    <p>Some text about this flip card.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Education<br /><b>study</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>
  {/* <!-- 6th -->
          <!-- Flip Card --> */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/05/restaurant-listing-3.jpg"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                   
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Back Title</h5>
                    <p>Some text about this flip card.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Places
                  <br /><b>Other</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/Listing-image-2.png"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                  {/* <!-- <div className="flip-text">
                    This is the third item with a longer title
                  </div> --> */}
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Back Title</h5>
                    <p>Some text about this flip card.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Pub & Bar<br /><b>Drunken Bar</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="flip-card">
              <div className="flip-card-inner">
                {/* <!-- Front --> */}
                <div className="flip-card-front">
                  <img
                    src="https://images.pexels.com/photos/18947580/pexels-photo-18947580/free-photo-of-photo-of-a-church-with-a-clock-tower-on-a-place.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Random Image"
                    className="img-fluid w-100 h-100"
                  />
                  {/* <!-- <div className="flip-text">
                    This is the third item with a longer title
                  </div> --> */}
                </div>
                {/* <!-- Back --> */}
                <div className="flip-card-back">
                  <div className="content-container">
                    <h5>Back Title</h5>
                    <p>Some text about this flip card.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="p-2">
                  <img
                    src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/icon-images-4.png"
                    alt=""
                    className="toprated_img"
                  />
                </div>
                <p className="p-1">Places<br /><b>others</b></p>
              </div>
              <div className="review">
                <h5>
                  Review<span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </h5>
              </div>
            </div>
          </div>

          {/* <!-- within row class --> */}
        </div>
        {/* <!-- end row --> */}
      </div>
    </section>
    </>
  )
}

export default Reviews
