import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import { Helmet } from 'react-helmet';
const Terms = () => {

  return (
    <div>
        <Helmet>
        <title>Tricity ~ Terms & Condition</title>
      </Helmet>
        <Header/>
        <section className="lttle_wraper" style={{marginTop: "7em"}}> 
   <div className="container">
     <div className="row"> 
       <h1 className="lttle_title text-center">Term Condition</h1>     

       <div className="col-lg-12">
         <div className="about-lttle pt-100">
           <div className="about-lttle__content">
             
             <p>
               We recognizes a special obligation to protect personal data
               of minors. Therefore, anyone under 18 years of age are
               requested not to use this site.
             </p>
             <p>
               This Privacy Policy was written in English. To the extent
               any translated version of this Privacy Policy conflicts with
               the English version, the English version prevails.
             </p>
             <br />
             <h6>YOUR PERSONAL DATA</h6>
             <br />
             <p>
               “Personal data” means any information from which it is
               practicable for the identity of an individual to be
               ascertained. The purposes for which we may use your personal
               data are divided into obligatory purposes and voluntary
               purposes. If personal data is to be used for an obligatory
               purpose, you must provide your personal data to us if you
               want us to provide the service for which you are applying.
               If personal data is to be used only for a voluntary purpose,
               it is entirely up to you to decide whether you want to
               provide such information to us or not.
             </p>
             <br />
             <h6>
               The personal data that we ask you to provide on a voluntary
               basis are:
             </h6>
             <br />
             <p>
               Name prefix (e.g., Mr., Ms.) Secondary telephone numbers
               Date of birth Passport / Emirates ID number any registration
               number previously issued to you by us
             </p>
             <p>
               The purposes for which it is only voluntary for you to
               provide your personal data are to enable us to communicate
               with littledraw.ae visitors and business partners, develop
               systems, services and procedures and information offerings
               tailored to your needs and perform market research. If any
               of our communications constitute direct marketing, we will
               separately seek your consent where required by applicable
               law.
             </p>
             <br />
             <h6>
               The personal data which is obligatory for you to provide in
               order to receive registration services are
             </h6>
             <br />
             <p>
               Full name Primary telephone numbers E-mail address
               Nationality Country of residence Passport / Emirates ID
               number any password you previously created for your
               registration.
             </p>
             <br />
             <h6>
               It is obligatory for you to provide your personal data in
               paragraph above for us to provide the following services:
             </h6>
             <br />
             <p>
               maintaining your registration; informing you of benefits
               available through your registration; relationship building;
               data cleansing and profile updates; enable us to process
               your order on littledraw.ae and detect and prevent fraud.
             </p>

             <br />
             <h6><span className="shape_circle">1</span>Your Information</h6>
             <br />
             <p>
               <strong>The type of information: </strong>we collect from
               you is either personal to you, or is general in nature:
             </p>
             <p>
               Personal Information: When you register on the Platform as a
               member, update your information, purchase any goods or
               services, take part in promotions or send emails to Us, you
               provide Us with information that We store and process. Such
               information may include your name, address, phone number,
               email address, purchase and transaction history, interests,
               and other such ‘Personal Information’.
             </p>
             <p>
               <strong>General Information: </strong>We also receive more
               general information from you as a result of your visits to,
               and use of, the Platform. This general information does not
               identify you personally, and can include information such as
               your IP address, the date and time you access the Platform,
               length of time you spend on the Platform, your browsing
               history (recorded by the text and graphics files that
               compose that page), the Internet address of the website from
               which you linked directly to our Platform, and other such
               ‘General Information’.
             </p>
             <br />
             <h6>
               <span className="shape_circle">2</span>Collecting and Using
               Information
             </h6>
             <br />
             <p>
               All credit/debit cards details and personally identifiable
               information will NOT be stored, sold, shared, rented or
               leased to any third parties.
             </p>
             <p>
               Most of the Platform can be used without giving Us any
               information. However, certain services may involve
               collection of information from you. We use this information
               to provide you with a better service, and in particular to:
               keep internal records; improve our products, services and
               the Platform; communicate with you if you have ordered,
               purchased or participated in anything on the Platform;
               contact you for market research purposes; and provide you
               with information about new opportunities, promotions,
               special offers and other information that We may feel is
               relevant to you. If you contact Us via the Platform, or via
               email to the contacts set out on the Platform, We may keep a
               record of that correspondence.
             </p>
             <p>
               We use General Information to help Us make the Platform more
               useful to visitors (including to help Us assess content of
               interest to visitors), and for other purposes such as
               determining the Platform’s technical design specifications
               and identifying system performance or problem areas, for
               tracking activity on the Platform, preparing reports,
               assessing trends, and otherwise monitoring the way in which
               the Platform is being used none of which would be in a way
               that personally identifies any users.
             </p>
            
           </div>
         </div>
         {/* <!-- about-wrapper--> */}
       </div>
     </div>
   </div>
 </section> 
 <Footer/>
    </div>
  )
}

export default Terms
