import React,{useEffect} from 'react'

const PlacesCarousel = () => {
    useEffect(() => {
        if (window.$) {
          window.$('.owl-carousel').owlCarousel({
            margin: 10,
            loop: true,
            autoplay: true,
    
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              1000: {
                items: 3,
              },
            },
          });
        }
      }, []);
  return (
    <>
      <section>
      <div className="home-demo">
        <h2>Discover Nearby Places</h2>

        <p className="home-demo-title">
          Duis vel interdum elit. Vivamus vel risus est. Integer a porta Quisque
          nisi felis, tincidunt cursus efficitur at.
        </p>
        <div className="owl-carousel owl-theme">
          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/05/restaurant-listing-3.jpg"
              alt=""
            />
            <p>
              Sed faucibus aliquam faucibus hasell. Lorem ipsum dolor sit amet,
              consectetur adipisci ng...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>
          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/03/Listing-image-3.png"
              alt=""
            />
            <p>
              Dolor sit amet lorem ipsum, consectetur adipisci ng elit. Sed
              faucibus aliquam faucibus...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>

          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/Listing-image-2.png"
              alt=""
            />
            <p>
              Worem ipsum dolor sit amet, consectetur adipisci ng elit. Sed
              faucibus aliquam faucibus...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>

          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/03/Listing-image-1.png"
              alt=""
            />
            <p>
              Bxrem ipsum dolor sit amet, consectetur adipisci ng elit. Sed
              faucibus aliquam faucibus...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>

          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/05/restaurant-listing-3.jpg"
              alt=""
            />
            <p>
              Worem ipsum dolor sit amet, consectetur adipisci ng elit. Sed
              faucibus aliquam faucibus...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>

          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/03/Listing-image-3.png"
              alt=""
            />
            <p>
              Worem ipsum dolor sit amet, consectetur adipisci ng elit. Sed
              faucibus aliquam faucibus...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>

          <div className="item">
            <img
              src="https://jusdial.wpengine.com/wp-content/uploads/2023/03/Listing-image-3.png"
              alt=""
            />
            <p>
              Worem ipsum dolor sit amet, consectetur adipisci ng elit. Sed
              faucibus aliquam faucibus...
            </p>
            <p className="address_location">
              <i className="fa fa-hand-o-right" style={{fontSize: "24px"}}></i>Canberra
              ACT, Australia, AU 2601
            </p>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default PlacesCarousel
