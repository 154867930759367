import React, { useEffect, useState } from "react";
import Header from "../Layouts/Header";
import Sidebar from "./Sidebar";
import Footer from "../Layouts/Footer";
import EventForm from "./EventForm";
import Apis from "../../tricitiApis";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const EventTable = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(true);
  const [events, setEvents] = useState([]);
  const sidebarToggle = () => {
    setIsSidebarActive(!isSidebarActive);
  };
  useEffect(() => {
    Apis.getEvents()
      .then((res) => {
        console.log(res.data);
        setEvents(res.data.event_details);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleDelete = (eventId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete event data !",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Apis.deleteEvents(eventId)
          .then((res) => {
            console.log(res.data);
            // Update the events state after successful deletion
            setEvents((prevEvents) =>
              prevEvents.filter((event) => event.id !== eventId)
            );
  
            toast.success(`${res.data.message}`);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  
  return (
    <div>
      <Header />
      <section className="listing_sidenav">
        <Sidebar
          sidebarToggle={sidebarToggle}
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />

        {/* <div className=" active-cont"> */}
        <div
          className={`p-1 my-container ${
            isSidebarActive ? "active-cont " : ""
          }`}
        >
          {/* <!-- Top Nav --> */}

          {/* <!--End Top Nav --> */}
          <div className="container">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="icons_wraper_dashborad">
                  <nav className="navbar top-navbar navbar-light">
                    <a className="btn border-0" id="menu-btn">
                      <i className="bx bx-menu" onClick={sidebarToggle}></i>
                    </a>
                  </nav>
                </div>
                <div className="page-title-heading">
                  <h1>Events</h1>
                  <p className="page-title-subheading">
                    This is an example dashboard created using build-in elements
                    and components.
                  </p>
                </div>
              </div>

              <div className="container row bg-white mt-4">
                <EventForm />
                <div className="card-body">
                  <div className="table-responsive">
                    <Table bordered hover>
                      {/* <table
                 className="table table-bordered"
                 id="dataTable"
                 width="100%"
                 cellspacing="0"
                  > */}
                      <thead>
                        <tr>
                          <th>id</th>
                          <th>User id</th>
                          <th>Events tittle</th>
                          {/* <th>Slug</th> */}
                          <th>Category</th>
                          <th>Sub category</th>
                          <th>Person name</th>
                          <th>Contact number</th>
                          <th>Email</th>
                          <th>Address</th>
                          <th>State</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Start date</th>
                          <th>End date</th>
                          <th>Timing</th>
                          <th>Event type</th>
                          <th>Entry fee</th>
                          <th>Short description</th>
                          <th>Long description</th>
                          <th>Approval status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <tr> */}
                        {events.length === 0 ? (
                          <tr>
                            <td colSpan="22"
                            className="text-center text-black-100"
                            >No events available</td>
                          </tr>
                        ) : (
                          events.map((elm, id) => {
                            return (
                              <>
                                <tr>
                                  {/* <th scope="row">{index + 1}</th> */}
                                  <td>{elm.id}</td>
                                  <td>{elm.user_id}</td>
                                  <td>{elm.event_tittle}</td>
                                  {/* <td>{elm.slug}</td> */}
                                  <td>{elm.category_id}</td>
                                  <td>{elm.sub_category}</td>
                                  <td>{elm.contact_person_name}</td>
                                  <td>{elm.contact_number}</td>
                                  <td>{elm.email}</td>
                                  <td>{elm.address}</td>
                                  <td>{elm.state}</td>
                                  <td>{elm.city}</td>
                                  <td>{elm.country}</td>
                                  <td>
                                    {new Date(
                                      elm.start_date
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>
                                    {new Date(
                                      elm.end_date
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{elm.timing}</td>
                                  <td>{elm.event_type}</td>
                                  <td>{elm.event_entry_fee}</td>
                                  <td>{elm.short_description}</td>
                                  <td>{elm.long_description}</td>
                                  <td>
                                  {elm.approval_status==="0" ?(
                                  <div className="badge badge-danger">Not Approved</div>
                                  ):(
                          <div className="badge badge-success">Approved</div>
                                  )}
                          </td>
                                  <td className="d-flex">
                                    <Button size="sm" variant="primary">
                                      <i className="fa fa-edit"></i>
                                    </Button>

                                    <Button
                                      size="sm"
                                      className="mx-2"
                                      variant="danger"
                                      onClick={() => handleDelete(elm.id)}
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                      {/* </table> */}
                    </Table>
                  </div>
                </div>
                <div className="card-footer small text-muted">
                  Updated yesterday at 11:59 PM
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default EventTable;
