import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Apis from "../../tricitiApis";
import { toast } from "react-toastify";

const Signup = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState(false);
  const [phonevalidation, setPhoneValidation] = useState(false);
  const [eye, seteye] = useState(false);
  
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [error, setError] = useState({
    name: false,
    email: false,
    phone: false,
    password: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
    if (name === "phone") {
      const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number, you can adjust the regex accordingly
      setPhoneValidation(!phoneRegex.test(value));
    } else {
      setError({
        ...error,
        [name]: false,
      });
  };
  }


  const handleSignup = (event) => {
    event.preventDefault();
    if (phonevalidation) {
      toast.error(`Please enter 10 digits phone munber`);
      setPhoneValidation(true);
      return;
    } else {
      Apis.usersignup({
        email: formdata.email,
        phone: formdata.phone,
        name: formdata.name,
      })
        .then((response) => {
          console.log("res", response);

          if (
            !formdata.name ||
            !formdata.email ||
            !formdata.phone ||
            response.data.message === "Validation errors"
          ) {
            // if ()
            console.log("login validation", response.data.message);
            toast.error(
              `${
                response.data.errors.name ||
                response.data.errors.email ||
                response.data.errors.phone
              }`
            );
            setError({
              name: response.data.errors.name ? true : false,
              email: response.data.errors.email ? true : false,
              phone: response.data.errors.phone ? true : false,
            });
          } else {
            toast.success(`${response.data.message}`,);
            setOtpSent(true);
            setShowOtpModal(true);
            setPhoneValidation(false);
          }
        })
        .catch((error) => {
          console.error("Error during signup:", error);
          toast.error(`${error.response.data.message}`);
        });
    }
  };

  const handleOtpVerification = () => {
    Apis.signupOtpVerify({ email: formdata.email, password: formdata.password })
      .then((response) => {
        if (
          response.data.success === false &&
          response.data.message === "Validation errors"
        ) {
          // console.log("vvv",response.data.message)
          toast.error(`${response.data.errors.password}`);
          setError({
            password: response.data.errors.password ? true : false,
          });
        } else {
          // console.log('otp verification', response.data);
          toast.success(`${response.data.message}`, {});
          // setFormData({
          //   name:"",
          //   phone:"",
          //   email:"",
          //   password: "",

          // })
          window.localStorage.setItem(
            "user-data",
            JSON.stringify(response.data)
          );
          Cookies.set("login", true);
          window.location.replace("/");
        }
      })
      .catch((error) => {
        console.error(
          "Error during OTP verification:",
          error.response.data.message
        );
        toast.error(`Please Enter valid otp`);
      });
    // }
  };

  return (
    <>
      <form className="signupScreen">
        <h1>Create Account</h1>
        <div className="social-container">
          <a href="#" className="social">
            <i className="fa fa-facebook-f" style={{ fontSize: "24px" }}></i>
          </a>
          <a href="#" className="social">
            <i className="fa fa-instagram" style={{ fontSize: "24px" }}></i>
          </a>
          <a href="#" className="social">
            <i
              className="fa fa-linkedin-square"
              style={{ fontSize: "24px" }}
            ></i>
          </a>
        </div>
        <span>or use your email for registration</span>
        <input
          type="text"
          placeholder="Name"
          name="name"
          value={formdata.name}
          onChange={handleChange}
          className={error.name ? "error" : ""}
        />
         {/* {error.name &&<p className='error-msg'>This field is required</p> } */}
        <input
          type="email"
          placeholder="Email"
          name="email"
          required
          value={formdata.email}
          onChange={handleChange}
          className={error.email ? "error" : ""}
        />
        <input
          type="tel"
          placeholder="Phone No."
          name="phone"
          required
          value={formdata.phone}
          onChange={handleChange}
          className={phonevalidation || error.phone ? "error" : ""}
        />
        <div className="otp_container">
          <button
            type="button"
            className="sign_login signup "
            onClick={handleSignup}
          >
            Send Otp
          </button>
          <div className="otp_send_link">
            {otpSent && (
              <a
                className="otp-link"
                style={{ cursor: "pointer" }}
                onClick={() => setShowOtpModal(true)}
              >
                Enter OTP here
              </a>
            )}
          </div>
        </div>
      </form>

      {/* OTP Modal */}
      <Modal
        show={showOtpModal}
        onHide={() => setShowOtpModal(false)}
        backdrop="static" // Prevents closing on click outside
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="otp">
            <Form.Label>Enter OTP received in your email</Form.Label>
            <div className=" pwd col-12 ">
              <Form.Control
                type={eye ? "text" : "password"}
                placeholder="Enter OTP"
                name="password"
                value={formdata.password}
                onChange={(e) => handleChange(e)}
                className={error.password ? "error" : ""}
              />
              <i
                onClick={() => seteye(!eye)}
                className={`fa ${eye ? "fa-eye" : "fa-eye-slash"}`}
                style={{ marginTop: "-30px", marginRight: "10px" }}
              ></i>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="sign_login  signup "
            onClick={() => setShowOtpModal(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="sign_login  signup "
            onClick={handleOtpVerification}
          >
            Sign up
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Signup;
