import React,{useState,useEffect} from 'react'
import validations from './validations';
import Apis from '../../tricitiApis';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Login = () => {
  const [minutes, setMinutes] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [countdownActive, setCountdownActive] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);

  const[eye,seteye]=useState(false);
  const [formdata,setFormData]=useState({
    email:"",
    password: "",
  })

useEffect(() => {
  const interval = setInterval(() => {
    if (countdownActive) {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setCountdownActive(false);
          setShowResendButton(true);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }
  }, 1000);
  return () => {
    clearInterval(interval);
  };
}, [seconds, minutes, countdownActive]);


const startResendCountdown = () => {
  setCountdownActive(true);
  setShowResendButton(false);
  setMinutes(1); // Set the desired minutes for the countdown
  setSeconds(0); // Set the desired seconds for the countdown
};

const [error, setError] = useState({
  email: false,
  password: false,
});

const handleChange = (event) => {
  const { name, value } = event.target;
  setFormData({
    ...formdata,
    [name]: value,
  });
  // Reset error state for the current field
  setError({
    ...error,
    [name]: false,
  });
};

  // const handleLogin = () => {
  //   Apis.userlogin({email:formdata.email})
  //   .then((response) => {         
  //       if(response.data.message==="Validation errors"){
  //         // console.log("login validation",response.data.message)
  //         toast.error(`${response.data.errors.email}`);
  //         setShowPassword(false);
  //       }
  //       else{
  //       toast.success(`${response.data.message}`, {
  //         });
  //         setShowPassword(true);
  //         startResendCountdown();
  //         setShowResendButton(true)
  //       }
  //     })
  //   .catch((error) => {
  //     // console.error("Error during signup:", error.response.data);
  //     toast.error(`${error.response.data.message}`,);
  //   });
  // }

  const handleLogin = (event) => {
    event.preventDefault();
    Apis.userlogin({ email: formdata.email })
      .then((response) => {
        if (response.data.message === "Validation errors") {
          toast.error(`${response.data.errors.email}`);
          setShowPassword(false);
          // Set error state for the email field
          setError({
            ...error,
            email: true,
          });
        } else {
          toast.success(`${response.data.message}`);
          setShowPassword(true);
          startResendCountdown();
          setShowResendButton(true);
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };
  // const handleVerifyOTP = () => {
  //   Apis.loginOtpVerify({email:formdata.email,password:formdata.password})
  //   .then((response)=>{
  //     if(response.data.message==="Validation errors"){
  //       // console.log("vvv",response.data.message)
  //       toast.error(`${response.data.errors.email || response.data.errors.password}`, {
  //       });
  //     }
  //     else{
  //       // console.log('otp verification', response.data);
  //     toast.success(`${response.data.message}`, {
  //     });
  //     setFormData({
  //       email:"",
  //       password: "",
  //     })
  //     window.localStorage.setItem('user-data', JSON.stringify(response.data));
  //     Cookies.set('login', true);
  //     window.location.replace('/') 
  //   }
  //   }).catch((error)=>{
  //     // console.log('otp verification failed', error);
  //     toast.error(`please Enter valid otp`,);
  //   });
  // };
  const handleVerifyOTP = (event) => {
    event.preventDefault();
    Apis.loginOtpVerify({ email: formdata.email, password: formdata.password })
      .then((response) => {
        if (response.data.message === "Validation errors") {
          toast.error(`${response.data.errors.email || response.data.errors.password}`);
          // Set error state for the email and password fields
          setError({
            email: response.data.errors.email ? true : false,
            password: response.data.errors.password ? true : false,
          });
        } else {
          toast.success(`${response.data.message}`);
          setFormData({
            email: "",
            password: "",
          });
          window.localStorage.setItem('user-data', JSON.stringify(response.data));
          Cookies.set('login', true);
          window.location.replace('/');
        }
      })
      .catch((error) => {
        toast.error(`Please enter a valid OTP`);
      });
  };
  // const Resendotp = (event) => {
  //   event.preventDefault();
  //   Apis.resendOtp({ email: formdata.email })
  //     .then((response) => {
  //       // console.log('resend otp res=', response);
  //       console.log('Resend OTP API Response:', response.data);
  //       toast.success(`${response.data.message}`, {
  //       });
  //       startResendCountdown();  // Start the countdown when OTP is resent
  //       setShowResendButton(true)
  //     })
  //     .catch((error) => {
  //       console.log('resend otp error', error.response.data.message);
  //       toast.error(`${error.response.data.message}`, {
  //       });
  //     });
  // };
  const Resendotp = (event) => {
    event.preventDefault();
    Apis.resendOtp({ email: formdata.email })
      .then((response) => {
        console.log('Resend OTP API Response:', response.data);
        toast.success(`${response.data.message}`);
        startResendCountdown();  // Start the countdown when OTP is resent
        setShowResendButton(true);
      })
      .catch((error) => {
        console.error('Resend OTP Error:', error.response.data.message);
        toast.error(`${error.response.data.message}`);
      });
  };
  



  return (
    <>
      <form className='loginScreen' onSubmit={handleLogin}  >
            <h1>Login in</h1>
            <div className="social-container">
              <Link to ="https://www.facebook.com" className="social"><i className="fa fa-facebook-f" style={{fontSize: "24px"}}></i></Link>
              <Link to ="https://www.instagram.com" className="social"><i className="fa fa-instagram" style={{fontSize: "24px"}}></i></Link>
              <Link to ="https://www.linkedin.com" className="social"><i className="fa fa-linkedin-square" style={{fontSize: "24px"}}></i></Link>
            </div>
            <span>or use your account</span>
            <input
        type="text"
        placeholder="Email"
        name='email'
        value={formdata.email}
        onChange={handleChange}
        className={error.email ? 'error shake' : ''}
      />
        {showPassword && (
         <div className=" pwd col-12 ">
      <input
         type={eye ? "text" : "password"}
        placeholder="Enter OTP"
        name='password'
        value={formdata.password}
        onChange={handleChange}
        className={error.password ? 'error' : ''}
        />
        <i onClick={() => seteye(!eye)} className={`fa ${eye ? "fa-eye" : "fa-eye-slash" }`}></i>
         </div>
         )}
            
          <button type="button" className="sign_login login" onClick={showPassword ? handleVerifyOTP : handleLogin}>
            {showPassword ? 'Login' : 'Send Otp'}
          </button>
          {showResendButton && (
                  seconds > 0 || minutes > 0 ? (
                    <p>
          Resend Otp in: {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
          </p>
          ) : (
            <p><button className="sign_login login"  onClick={(event) => Resendotp(event)}>
            Resend OTP
            </button></p> 
            )
      )} 
          </form>
    </>
  )
}

export default Login

